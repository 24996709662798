import { cn } from "#app/utils/misc.tsx";

export function ImageWindow({ children, className, border = true }: { children: React.ReactNode, className?: string, border?: boolean}) {
    return (
      <div
        className={cn(
          'relative overflow-hidden shadow-xl flex bg-slate-200 dark:bg-slate-800 h-[15.625rem] max-h-[85vh] sm:max-h-[none] md:max-h-[60vh] rounded-xl lg:h-[38rem] xl:h-[36rem] dark:bg-slate-900/70 dark:backdrop-blur dark:ring-1 dark:ring-inset dark:ring-white/10',
          className
        )}
      >
        <div className="relative w-full flex flex-col">
          <div className={cn('flex-none', border && 'border-b border-slate-500/30')}>
            <div className="flex items-center h-8 space-x-1.5 px-3">
              <div className="w-2.5 h-2.5 bg-slate-600 rounded-full" />
              <div className="w-2.5 h-2.5 bg-slate-600 rounded-full" />
              <div className="w-2.5 h-2.5 bg-slate-600 rounded-full" />
            </div>
            {/* <div className="h-px bg-gradient-to-r from-sky-300/0 via-sky-300/20 to-sky-300/0" /> */}
          </div>
          <div className="relative min-h-0 flex-auto flex flex-col border-1 border-white/10 p-0.5 ">{children}</div>
        </div>
      </div>
    )
}